import React from 'react'
import Navbar from "./Navbar/Navbar.jsx";
import Calculator from './Calculator/Calculator.jsx';
import Footer from './Footer/Footer.jsx';
import StepbyStep from './Body/StepbyStep.jsx';
import FAQ from './Body/FAQ.jsx';
import RocketHireSpline from './Body/RocketHireSpline.jsx';
import BenefitsHero from './Body/BenefitsHero.jsx';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import AboutUs from './Body/AboutUs.jsx';
import BookACall from './Body/BookACall.jsx';
import TopBanner from './Body/TopBanner.jsx';
import ManatalJobsApply from './Body/ManatalJobsApply.jsx';
import RolesWeHire from './Body/RolesWeHire.jsx';




const FullApp = () => {


  return (
    <div>




          <div className='pt-20'>
            <RocketHireSpline/>
          </div>

          <div>
            <TopBanner/>
          </div>

          <div>
            <RolesWeHire/>
          </div>

          <div id='stepbystep'>
            <StepbyStep/>
          </div>

          <div id='aboutus'>
            <AboutUs/>
          </div>

{/*

          <div>
            <BenefitsHero/>
          </div>
*/}

          <div>
            <BookACall/>
          </div>


{/*
          <div id='calculator'>
            <Calculator/>
          </div>

*/}


          

{/*

        <div>
          <ManatalJobsApply/>
        </div>
*/}

        <div id='faq'>
          <FAQ/>
        </div>


          


      
    </div>
  )
}
/*        <div className='absolute inset-0 blur-[258px] max-w-lg h-[1800px] mx-auto sm:max-w-3xl sm:h-[1400px]' style={{ background: "linear-gradient(106.89deg, #304269 50%, #F26101 95%, #D9E8F5 10%)" }}></div>
 */
export default FullApp
