import React from 'react'
import indeedlogo from '../../assets/indeedlogo.png'
import linkedinlogo from '../../assets/linkedinlogo.png'

const OpenJobs = () => {
  return (
    <div className='pt-24'>
        <div class="relative w-full h-full">
        <div class="absolute hidden w-full bg-white lg:block h-96" />
        <div class="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <h2 class="max-w-lg mb-6 text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                <span class="relative inline-block">

                <span class="relative">Your Next <span className='text-rockethire-orange'>Opportunity</span> Starts Here!</span>
                </span>{' '}
                
            </h2>
            <p class="text-base text-gray-700 md:text-lg">
            At <span className="navbar-logo-title text-2xl text-black">ROCKET<span className="text-rockethire-orange">HIRE</span></span>, we’re always on the lookout for skilled and passionate professionals to join our growing team. 
            Below, you’ll find our current job openings. We encourage you to apply to any role that matches
            your experience and career goals.
            </p>


            </div>
            <div class="grid max-w-screen-md gap-10 md:grid-cols-2 sm:mx-auto">
                
            <div>
                <div class="p-8 bg-[#ececec59] rounded border-[#a0a0a0] border-2">
                    <img className='' src={indeedlogo} alt='indeed logo'/>

                <p className="text-sm text-black pb-8">
                  Apply directly on our Indeed page. 
                </p>

             <div> {/* LIST */}
                
             {/*}   <div class="mb-4 text-center">
                    <p class="text-xl font-medium tracking-wide text-white">
                    Starter Plan
                    </p>
                    <div class="flex items-center justify-center">
                    <p class="mr-2 text-5xl font-semibold text-white lg:text-6xl">
                        $39
                    </p>
                    <p class="text-lg text-gray-500">/ month</p>
                    </div>
                </div> 

                <ul class="mb-8 space-y-2">
                    <li class="flex items-center">
                    <div class="mr-3">
                        <svg
                        class="w-4 h-4 text-black"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeWidth="2"
                        >
                        <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                        />
                        <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                        />
                        </svg>
                    </div>
                    <p class="font-medium text-black">10 deploys per day</p>
                    </li>
                    <li class="flex items-center">
                    <div class="mr-3">
                        <svg
                        class="w-4 h-4 text-black"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeWidth="2"
                        >
                        <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                        />
                        <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                        />
                        </svg>
                    </div>
                    <p class="font-medium text-black">10 GB of storage</p>
                    </li>
                    <li class="flex items-center">
                    <div class="mr-3">
                        <svg
                        class="w-4 h-4 text-black"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeWidth="2"
                        >
                        <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                        />
                        <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                        />
                        </svg>
                    </div>
                    <p class="font-medium text-black">3 domains</p>
                    </li>
                    <li class="flex items-center">
                    <div class="mr-3">
                        <svg
                        class="w-4 h-4 text-black"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeWidth="2"
                        >
                        <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                        />
                        <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                        />
                        </svg>
                    </div>
                    <p class="font-medium text-black">SSL Certificates</p>
                    </li>
                </ul>
                */}
            </div>
                <a
                  href="https://www.indeed.com/cmp/Rockethire-1/jobs#cmp-skip-header-desktop"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-[#3360AA] hover:bg-[#0a66c2be] focus:outline-none focus:ring-2 focus:ring-[#3360AA] focus:ring-opacity-50"
                  >
                  Available Jobs
                </a>

                </div>
                <div class="w-11/12 h-2 mx-auto bg-gray-900 rounded-b opacity-75" />
                <div class="w-10/12 h-2 mx-auto bg-gray-900 rounded-b opacity-50" />
                <div class="w-9/12 h-2 mx-auto bg-gray-900 rounded-b opacity-25" />
            </div>
            <div>
                <div class="p-8 bg-[#ececec59] rounded border-[#a0a0a0] border-2">

                <img className='pb-9 pt-4 ' src={linkedinlogo} alt='linkedin logo'/>

                <p className="text-sm text-black pb-8">
                  Apply directly on our LinkedIn page. 
                </p>

                <div> {/* LIST */}

             {/*   <div class="mb-4 text-center">
                    <p class="text-xl font-medium tracking-wide text-white">
                    Pro Plan
                    </p>
                    <div class="flex items-center justify-center">
                    <p class="mr-2 text-5xl font-semibold text-white lg:text-6xl">
                        $59
                    </p>
                    <p class="text-lg text-gray-500">/ month</p>
                    </div> 
                </div>

                <ul class="mb-8 space-y-2">
                    <li class="flex items-center">
                    <div class="mr-3">
                        <svg
                        class="w-4 h-4 text-black"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeWidth="2"
                        >
                        <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                        />
                        <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                        />
                        </svg>
                    </div>
                    <p class="font-medium text-black">100 deploys per day</p>
                    </li>
                    <li class="flex items-center">
                    <div class="mr-3">
                        <svg
                        class="w-4 h-4 text-black"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeWidth="2"
                        >
                        <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                        />
                        <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                        />
                        </svg>
                    </div>
                    <p class="font-medium text-black">50 GB of storage</p>
                    </li>
                    <li class="flex items-center">
                    <div class="mr-3">
                        <svg
                        class="w-4 h-4 text-black"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeWidth="2"
                        >
                        <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                        />
                        <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                        />
                        </svg>
                    </div>
                    <p class="font-medium text-black">Unlimited domains</p>
                    </li>
                    <li class="flex items-center">
                    <div class="mr-3">
                        <svg
                        class="w-4 h-4 text-black"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeWidth="2"
                        >
                        <polyline
                            fill="none"
                            stroke="currentColor"
                            points="6,12 10,16 18,8"
                        />
                        <circle
                            cx="12"
                            cy="12"
                            fill="none"
                            r="11"
                            stroke="currentColor"
                        />
                        </svg>
                    </div>
                    <p class="font-medium text-black">SSL Certificates</p>
                    </li>
                </ul>
                */}
                </div>
                
                <a
                  href="https://www.linkedin.com/company/rockethire/jobs/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-[#0A66C2] hover:bg-[#0a66c2be] focus:outline-none focus:ring-2 focus:ring-[#3360AA] focus:ring-opacity-50"
                  >
                  Available Jobs
                </a>

                </div>
                <div class="w-11/12 h-2 mx-auto bg-gray-900 rounded-b opacity-75" />
                <div class="w-10/12 h-2 mx-auto bg-gray-900 rounded-b opacity-50" />
                <div class="w-9/12 h-2 mx-auto bg-gray-900 rounded-b opacity-25" />
            </div>
            </div>
                <p className='pt-24 max-w-2xl mb-10 md:mx-auto sm:text-center lg:max-w-3xl md:mb-12'>
                    We’re still interested in hearing from you! If you don’t find an open position that
                     matches your skills, don’t worry. We’re always accepting resumes for future 
                     opportunities. Send your resume to <span className='text-[#67ace6] font-bold'><a href='mailto:info@rockethire.ca'>info@rockethire.ca</a></span>, and we’ll reach out when 
                     a position opens that suits your profile.
                </p>
        </div>
        </div>
        
    </div>
  )
}

export default OpenJobs
