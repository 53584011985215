import { useState, useEffect } from 'react';
import calendar from "../../assets/calendar.png";
import coffee from "../../assets/coffee.png";
import tv from "../../assets/tv.png";
import palm from "../../assets/palm.png";

export default function Herodiv() {
    const [role, setRole] = useState('');
    const [salary, setSalary] = useState(0); // Default salary to 0
    const [fluency, setFluency] = useState('');
    const [continent, setContinent] = useState(''); // New state for continent
    const [savings, setSavings] = useState(null);

    const roleSalaries = {
        "Sales Representative": 1500,
        "Customer Service Representative": 1200,
        "Technical Support Specialist": 1400,
        "Inside Sales Associate": 1500,
        "Lead Generation Specialist": 1300,
        "Account Manager": 1200,
        "Customer Success Manager": 1200,
    };

    const fluencyAdjustments = {
        "Professional": 100,
        "Fluent": 200,
    };

    const continentAdjustments = {
        "South America": 100,
        "Asia": -100,
        "Africa": 0,
    };

    useEffect(() => {
        if (role && fluency && continent) {
            const totalCost = (roleSalaries[role] || 0) + (fluencyAdjustments[fluency] || 0) + (continentAdjustments[continent] || 0);
            const calculatedSavings = salary - totalCost;
            setSavings(calculatedSavings > 0 ? calculatedSavings : 0);
        } else {
            setSavings(null);
        }
    }, [role, salary, fluency, continent]); // Add continent to dependency array

    return (
        <div className='py-36'>
            <div className="mx-auto max-w-4xl p-8 bg-white rounded-2xl shadow-md border">
                <div className="flex flex-col lg:flex-row gap-8">
                    <div className="flex-1">
                        <div className="flex-1 space-y-6">
                            <h1 className='text-3xl font-bold'>Calculate how much a <span className='navbar-logo-title text-4xl'>ROCKET<span className='text-rockethire-orange'>HIRE</span></span> would save you.</h1>
                            <div className="mt-4">
                                <h1 className='text-xl pb-4'>Tell us more about the role you are looking for:</h1>
                                <select
                                    className="block w-full rounded-xl border py-2.5 pl-7 pr-20 text-gray-900 shadow-md focus:ring-2 focus:ring-indigo-600"
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                >
                                    <option value="">Select your role</option>
                                    <option value="Sales Representative">Sales Representative</option>
                                    <option value="Customer Service Representative">Customer Service Representative</option>
                                    <option value="Technical Support Specialist">Technical Support Specialist</option>
                                    <option value="Inside Sales Associate">Inside Sales Associate</option>
                                    <option value="Lead Generation Specialist">Lead Generation Specialist</option>
                                    <option value="Account Manager">Account Manager</option>
                                    <option value="Customer Success Manager">Customer Success Manager</option>
                                </select>
                                <select
                                    className="block w-full rounded-xl border py-2.5 pl-7 pr-20 text-gray-900 shadow-md focus:ring-2 focus:ring-indigo-600 mt-6 mb-6"
                                    value={continent}
                                    onChange={(e) => setContinent(e.target.value)}
                                >
                                    <option value="">Location of the Worker you Would like to hire</option>
                                    <option value="South America">South America</option>
                                    <option value="Asia">Asia</option>
                                    <option value="Africa">Africa</option>
                                </select>
                                <select
                                    className="block w-full rounded-xl border py-2.5 pl-7 pr-20 text-gray-900 shadow-md focus:ring-2 focus:ring-indigo-600 mt-6 mb-6"
                                    value={fluency}
                                    onChange={(e) => setFluency(e.target.value)}
                                >
                                    <option value="">Select your English fluency</option>
                                    <option value="Professional">Professional</option>
                                    <option value="Fluent">Fluent</option>
                                </select>
                                <div className="text-center p-4 bg-white rounded-xl border shadow-md">
                                    <div>
                                        <label htmlFor="price" className="block leading-6 text-black">
                                            How much are you paying for this role a month locally?
                                        </label>
                                        <div className="relative mt-2 rounded-md shadow-sm">
                                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                <span className="text-gray-500 sm:text-sm">$</span>
                                            </div>
                                            <input
                                                type="number"
                                                name="price"
                                                id="price"
                                                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                placeholder="0.00"
                                                value={salary}
                                                onChange={(e) => setSalary(Number(e.target.value))}
                                                min="1600"
                                                max="10000"
                                                step="100"
                                            />
                                            <div className="absolute inset-y-0 right-0 flex items-center">
                                                <label htmlFor="currency" className="sr-only">
                                                    Currency
                                                </label>
                                                <select
                                                    id="currency"
                                                    name="currency"
                                                    className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                                                >
                                                    <option>USD</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <input
                                        type="range"
                                        className="w-1/2 h-2 bg-gray-300 rounded-lg cursor-pointer mx-auto"
                                        min="1600"
                                        max="10000"
                                        step="100"
                                        value={salary}
                                        onChange={(e) => setSalary(Number(e.target.value))}
                                    />
                                    <div className="flex justify-between text-xs px-2">
                                        <span className='pl-24'>$1600</span>
                                        <span className='pr-24'>$10000</span>
                                    </div>
                                </div>
                            </div>
                            <h2 className="text-3xl font-bold text-gray-900">
                                Every month, you could be saving <span className="text-rockethire-orange">${savings}</span> with our services.
                            </h2>
                            <div className="grid max-w-screen-lg gap-8 row-gap-5 mb-8 sm:grid-cols-2 lg:grid-cols-4 sm:mx-auto">
                                <div className="bg-white p-4 shadow-lg rounded-lg flex flex-col justify-center items-center">
                                    <img className='h-8 mx-auto' src={calendar} alt="calendar" />
                                    <h5 className="font-semibold">Every year, that would be</h5>
                                    <span className='text-rockethire-orange font-bold'>${savings ? (savings * 12).toLocaleString() : 0}</span> <span className='font-semibold'>in salaries saved.</span>
                                </div>
                                <div className="bg-white p-4 shadow-lg rounded-lg flex flex-col justify-center items-center">
                                    <img className='h-8 mx-auto' src={coffee} alt="coffee" />
                                    <h5 className="font-semibold">Which translates to about</h5>
                                    <span className='text-rockethire-orange font-bold'>{savings ? ((savings * 12) / 2).toLocaleString() : 0}</span> <span className='font-semibold'>cups of coffee every year</span>
                                </div>
                                <div className="bg-white p-4 shadow-lg rounded-lg flex flex-col justify-center items-center">
                                    <img className='h-8 mx-auto' src={tv} alt="tv" />
                                    <h5 className="font-semibold">Or around</h5>
                                    <span className='text-rockethire-orange font-bold'>{savings ? Math.trunc((savings * 12) / 7).toLocaleString() : 0}</span> <span className='font-semibold'>Netflix subscriptions</span>
                                </div>
                                <div className="bg-white p-4 shadow-lg rounded-lg flex flex-col justify-center items-center">
                                        <img className='h-8 mx-auto' src={palm}/>
                                        <h5 className="font-semibold">It could also be </h5>
                                        <span className='text-rockethire-orange font-bold'> ${savings ? (savings * 12).toLocaleString() : 0}</span><span className='font-semibold'> in downpayment for that beach house in the Bahamas</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
