import './App.css';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FullApp from './components/FullApp';
import Navbar from './components/Navbar/Navbar.jsx';
import Footer from './components/Footer/Footer.jsx'; 
import AboutUs from './components/Body/AboutUs.jsx';
import Pricing from './components/Pricing/Pricing.jsx';
import Calculator from './components/Calculator/Calculator.jsx';
import OpenJobs from './components/OpenJobs/OpenJobs.jsx';

function App() {
  // tracking ID
  ReactGA.initialize('G-4FXN8SWRQR');

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    // Flags to ensure we track each scroll threshold only once
    let hasTracked25 = false;
    let hasTracked50 = false;
    let hasTracked75 = false;
    let hasTracked100 = false;

    
    const handleScroll = () => {
      const scrollDepth = (window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100;

      if (scrollDepth >= 25 && !hasTracked25) {
        ReactGA.event({
          category: 'Engagement',
          action: 'Scrolled 25%',
          label: 'User Engagement'
        });
        hasTracked25 = true; // Prevents firing again for 25%
      }

      if (scrollDepth >= 50 && !hasTracked50) {
        ReactGA.event({
          category: 'Engagement',
          action: 'Scrolled 50%',
          label: 'User Engagement'
        });
        hasTracked50 = true; // Prevents firing again for 50%
      }

      if (scrollDepth >= 75 && !hasTracked75) {
        ReactGA.event({
          category: 'Engagement',
          action: 'Scrolled 75%',
          label: 'User Engagement'
        });
        hasTracked75 = true; // Prevents firing again for 75%
      }

      if (scrollDepth >= 100 && !hasTracked100) {
        ReactGA.event({
          category: 'Engagement',
          action: 'Scrolled 100%',
          label: 'User Engagement'
        });
        hasTracked100 = true; // Prevents firing again for 100%
        window.removeEventListener('scroll', handleScroll); 
      }
    };

    
    window.addEventListener('scroll', handleScroll);

    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <div>
          <Navbar />
        </div>
          <div className=''>
            <Routes>
              <Route path="/" element={<FullApp />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/calculator" element={<Calculator />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/openjobs" element={<OpenJobs />} />
            </Routes>
          </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;